import React from 'react'
import { AsH3, ParagraphMD, TitleLG } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

interface PayItForwardOurMissionProps {
  description: string
  className?: string
}

export const PayItForwardOurMission: React.FC<PayItForwardOurMissionProps> = ({ description, className }) => {
  const { t } = useTranslate('pif')

  return (
    description && (
      <section id="pay-it-forward-our-mission" className={className}>
        <TitleLG weight="bold" as={AsH3} color="core-gray-950" className="mb-4">
          {t('ourMission', 'Our Mission')}
        </TitleLG>
        <ParagraphMD color="core-gray-700">{description}</ParagraphMD>
      </section>
    )
  )
}
