import dynamic, { DynamicOptions } from 'next/dynamic'
import { cLogger } from '@/utils/logging/client-logger'
import type { PifExplainerVideoPlayerProps } from './types'

export const PifExplainerVideoPlayer = dynamic(
  import('./PifExplainerVideoPlayer')
    .then((mod) => mod.PifExplainerVideoPlayer)
    .catch((err) =>
      cLogger().error(`Failed to load the PifExplainerVideoPlayer!`, err),
    ) as DynamicOptions<PifExplainerVideoPlayerProps>,
  { ssr: false },
)
