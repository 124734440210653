import React from 'react'
import classNames from 'classnames'
import { CaptionLG } from '@/atoms/Text'
import { Offer } from '@/services/PifService'
import { formatPrice } from '@/services/PriceFormatter'

interface PriceButtonProps {
  onClick: (offer: Offer) => void
  offer: Offer
  selectedOffer?: Offer | null
}

export const PriceButton: React.FC<PriceButtonProps> = ({ onClick, selectedOffer, offer }) => {
  const price = formatPrice(offer.price, { includeDecimals: false, currency: offer.currency })

  return (
    <div className="flex-basis-auto shrink-0 grow cursor-pointer text-center" onClick={() => onClick(offer)}>
      <CaptionLG
        weight="bold"
        className={classNames(
          offer.id === selectedOffer?.id ? 'border-gray-900 bg-gray-900 text-white' : 'border-gray-200 bg-gray-100',
          'rounded-xl border-2 p-2 hover:bg-gray-700 hover:border-gray-700 hover:text-white',
        )}
      >
        {price}
      </CaptionLG>
    </div>
  )
}
