import { Offer } from '@/services/PifService'
import { PriceButton } from './PriceButton'

interface PriceOptionsProps {
  offers: Offer[]
  onClick: (offer: Offer) => void
  selectedOffer: Offer | null
}

export const PriceOptions: React.FC<PriceOptionsProps> = ({ offers, onClick, selectedOffer }) => {
  return (
    <div className="flex flex-wrap gap-1">
      {offers?.map((offer) => (
        <PriceButton key={offer.id} offer={offer} selectedOffer={selectedOffer} onClick={onClick} />
      ))}
    </div>
  )
}
