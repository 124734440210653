import React from 'react'
import classNames from 'classnames'
import { PayItForwardOutlineIcon } from '@/atoms/Icons/PayItForwardOutlineIcon'
import { CaptionMD, TitleMD, TitleSM } from '@/atoms/Text'
import { Project } from '@/services/ProjectsService'
import { useTranslate } from '@/utils/translate/translate-client'

interface OfferSelectorHeaderProps {
  projectName: Project['name']
  selectedOfferPrice?: string | null
  hidePrice?: boolean
  className?: string
}

export const OfferSelectorHeader: React.FC<OfferSelectorHeaderProps> = ({
  projectName,
  selectedOfferPrice,
  hidePrice,
  className,
}) => {
  const { t } = useTranslate('pif')

  return (
    <div className={classNames('flex flex-wrap items-center justify-between gap-4', className)}>
      <div className={classNames('flex gap-2', { 'flex-col items-center text-center mx-auto': hidePrice })}>
        <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gray-200">
          <PayItForwardOutlineIcon size={24} color="black" />
        </div>
        <div className="flex flex-col justify-center">
          <TitleSM color="gray-950" weight="bold">
            {t('payItForwardv2', 'Pay it Forward')}
          </TitleSM>
          <CaptionMD color="gray-600">{projectName}</CaptionMD>
        </div>
      </div>
      {selectedOfferPrice && !hidePrice && (
        <TitleMD weight="bold" className="self-start">
          {selectedOfferPrice}
        </TitleMD>
      )}
    </div>
  )
}
