import React from 'react'
import { CaptionMD } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { PerkItem } from './PerkItem'
import { PerksProps } from './PerkTypes'

export const PerksViewer: React.FC<PerksProps> = ({ isSubscription, projectName, projectSlug, selectedOffer }) => {
  const { t } = useTranslate('pif')

  if (!selectedOffer) return null
  const offersPerks = selectedOffer?.offeredproductSet
  const votingAccess = t('votingAccessPerk', '*Vote on what amazing movies or TV shows we make next')
  return (
    <div className="my-6 flex flex-col gap-2">
      {offersPerks && (
        <>
          <CaptionMD weight="bold">{t('yourPerksAndImpact', 'Your Perks and Impact')}</CaptionMD>
          {offersPerks.map((perk) => (
            <PerkItem key={perk.id} projectName={projectName} perk={perk} isActive={true} />
          ))}
        </>
      )}
      {isSubscription && projectSlug !== slugs.theChosen && <PerkItem perk={votingAccess} isActive={true} />}
    </div>
  )
}
