import React from 'react'
import { getHexColor, TwoColorIconProps } from '../utils'

export const CircleDollarFilled: React.FC<TwoColorIconProps> = ({
  size = 40,
  color1 = 'gray-100',
  color2 = 'oxide-700',
  className,
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.510864" width="40" height="40" rx="20" fill={c2} />
      <path d="M20 16.1359V17.3859" stroke={c1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 23.6359V24.8859" stroke={c1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20 28.0109C24.1421 28.0109 27.5 24.653 27.5 20.5109C27.5 16.3687 24.1421 13.0109 20 13.0109C15.8579 13.0109 12.5 16.3687 12.5 20.5109C12.5 24.653 15.8579 28.0109 20 28.0109Z"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.125 23.6359H20.9375C21.3519 23.6359 21.7493 23.4712 22.0424 23.1782C22.3354 22.8852 22.5 22.4878 22.5 22.0734C22.5 21.659 22.3354 21.2615 22.0424 20.9685C21.7493 20.6755 21.3519 20.5109 20.9375 20.5109H19.0625C18.6481 20.5109 18.2507 20.3462 17.9576 20.0532C17.6646 19.7602 17.5 19.3628 17.5 18.9484C17.5 18.534 17.6646 18.1365 17.9576 17.8435C18.2507 17.5505 18.6481 17.3859 19.0625 17.3859H21.875"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
