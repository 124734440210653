import React from 'react'
import classNames from 'classnames'
import { Else, If, Then } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  offerPath: string
  handleClick: () => void
  className?: string
  showPrice?: boolean
  selectedOfferPrice?: string | null
}

export const DefaultButton: React.FC<Props> = ({
  selectedOfferPrice,
  offerPath,
  handleClick,
  className,
  showPrice,
}) => {
  const { t } = useTranslate('pif')

  return (
    <LinkButton
      href={offerPath}
      variant={'oxide-core'}
      className={classNames('mt-6 h-[56px] w-full rounded-xl !bg-[#03A67B] !text-white hover:!bg-[#16B087]', className)}
      onClick={handleClick}
    >
      <PayItForwardIcon color="white" className="mr-1.5" />
      <If condition={showPrice}>
        <Then>
          <Translate i18nKey="payItForwardAmount" t={t} values={{ selectedOfferPrice }}>
            Pay it Forward {{ selectedOfferPrice }}
          </Translate>
        </Then>
        <Else>{t('payItForwardV2', 'Pay it Forward')}</Else>
      </If>
    </LinkButton>
  )
}
