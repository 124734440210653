import { FC, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'
import { Image } from '@/atoms/Image'
import { Offer } from '@/services/PifService'
import { formatPrice, getPriceForCurrency } from '@/services/PriceFormatter'
import { Project } from '@/services/ProjectsService'
import { MoneyCurrencyCode } from '@/types/codegen-federation'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { useSelectOffer } from '@/views/PayItForward/UseSelectOffer'
import { OfferCheckoutButton } from './OfferCheckoutButton'
import { OfferSelectorHeader } from './OfferSelectorHeader'
import { PerksViewer } from './PerksViewer'
import { PriceOptions } from './PriceOptions'
import { bannerImages } from './data'

interface OfferSelectorProps {
  projectName: Project['name']
  projectSlug: Project['slug']
  offers: Offer[]
  featuredOffer?: Offer
  onChange: (inView: boolean) => void
  showBanner?: boolean
}

export const PayItForwardExpressCheckoutContainer: FC<OfferSelectorProps> = ({
  projectName,
  projectSlug,
  offers,
  featuredOffer,
  onChange,
  showBanner = false,
}) => {
  const { selectedOffer, setSelectedOffer } = useSelectOffer({ offers, defaultOfferId: featuredOffer?.id })
  const ref = useRef<HTMLElement>(null)
  const { trackProductViewed } = useEcommerceEvents()
  const bannerUrl =
    bannerImages && projectSlug
      ? bannerImages.filter((banner) => banner.projectSlug === projectSlug)[0]?.bannerUrl
      : null

  const allPerks = useMemo(() => {
    if (!offers?.length) {
      return []
    }

    const perkIds: Record<string, boolean> = {}
    const perksList = []

    for (const offer of offers) {
      const sortedProductSet = [...(offer?.offeredproductSet ?? [])].sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))

      for (const perk of sortedProductSet) {
        const perkProduct = perk?.product

        if (perkProduct && !perkIds[perkProduct.id]) {
          perkIds[perkProduct.id] = true
          perksList.push({
            id: perkProduct.id,
            perk,
          })
        }
      }
    }

    return perksList
  }, [offers])

  if (!offers) return null

  const offersToShow = offers
    .map((offer) => {
      if (offer.currency.toUpperCase() === 'USD') {
        return offer.price <= 1000000 ? offer : null
      } else {
        return offer
      }
    })
    .filter((offer) => offer !== null) as Offer[]

  const handleSelectedOffer = (offer: Offer) => {
    setSelectedOffer(offer)
  }

  const selectedOfferPrice =
    selectedOffer &&
    formatPrice(selectedOffer?.price, {
      includeDecimals: false,
      currency: selectedOffer?.currency,
    })

  return (
    <div ref={ref as React.RefObject<HTMLDivElement>}>
      <InView id="pay-it-forward-offers" as="section" onChange={onChange}>
        {/* Nested InView because this one should only trigger once */}
        <InView
          onChange={(inView) => {
            if (!inView || !selectedOffer || !featuredOffer) return

            trackProductViewed({
              funnel: 'pif',
              product_id: selectedOffer.id,
              sku: selectedOffer.slug,
              category: 'pif',
              name: selectedOffer.name,
              price: getPriceForCurrency(selectedOffer.price, selectedOffer.currency),
              currency: selectedOffer.currency as MoneyCurrencyCode,
              quantity: 1,
              projectSlug,
            })
          }}
          triggerOnce
        >
          {showBanner && (
            <div className="relative w-screen">
              <Image
                alt={projectName as string}
                src={getCloudinaryImageUrl({ path: bannerUrl ?? 'v1698945632/angel-studios/Angel.png' })}
                width="0"
                height="0"
                sizes="100vw"
                className="h-auto w-full"
              />
            </div>
          )}
          <div
            className={classNames(
              'w-full border border-gray-200 bg-white px-6 py-8 md:mb-0',
              showBanner ? 'rounded-none md:rounded-3xl md:mb-12' : 'rounded-3xl mb-12',
            )}
          >
            <OfferSelectorHeader
              projectName={projectName}
              selectedOfferPrice={selectedOfferPrice}
              hidePrice={false}
              className={'mb-4 hidden sm:flex'}
            />
            <PriceOptions offers={offersToShow} onClick={handleSelectedOffer} selectedOffer={selectedOffer} />
            <PerksViewer
              allPerks={allPerks}
              isSubscription={false}
              projectName={projectName}
              projectSlug={projectSlug}
              selectedOffer={selectedOffer}
            />
            <div className="fixed inset-x-0 bottom-0 z-[9001] sm:relative lg:z-[5]">
              <div className="h-10 w-full bg-gradient-to-t from-core-gray-400 to-transparent sm:hidden" />

              <div className={'border-t-[1px] border-core-gray-200 bg-white p-4 sm:border-0 sm:p-0'}>
                <OfferCheckoutButton
                  className={'!mt-0'}
                  selectedOffer={selectedOffer}
                  projectSlug={projectSlug}
                  isSubscription={false}
                  selectedOfferPrice={selectedOfferPrice}
                  showPrice={true}
                />
              </div>
            </div>
          </div>
        </InView>
      </InView>
    </div>
  )
}
