import React from 'react'
import { getHexColor, TwoColorIconProps } from '../utils'

export const CircleTicketFilled: React.FC<TwoColorIconProps> = ({
  size = 40,
  color1 = 'gray-100',
  color2 = 'oxide-700',
  className,
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.333374" y="0.510864" width="40" height="40" rx="20" fill={c2} />
      <path d="M17.8334 14.8859V26.1359" stroke={c1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.2084 23.5726C12.2083 23.4286 12.2581 23.289 12.3492 23.1776C12.4403 23.0661 12.5672 22.9895 12.7083 22.9609C13.273 22.8455 13.7804 22.5387 14.1449 22.0922C14.5093 21.6458 14.7084 21.0872 14.7084 20.5109C14.7084 19.9346 14.5093 19.3759 14.1449 18.9295C13.7804 18.4831 13.273 18.1762 12.7083 18.0609C12.5672 18.0322 12.4403 17.9557 12.3492 17.8442C12.2581 17.7327 12.2083 17.5931 12.2084 17.4491V15.5109C12.2084 15.3451 12.2742 15.1861 12.3914 15.0689C12.5086 14.9517 12.6676 14.8859 12.8334 14.8859H27.8334C27.9991 14.8859 28.1581 14.9517 28.2753 15.0689C28.3925 15.1861 28.4584 15.3451 28.4584 15.5109V17.4491C28.4584 17.5931 28.4086 17.7327 28.3175 17.8442C28.2264 17.9557 28.0995 18.0322 27.9584 18.0609C27.3938 18.1762 26.8863 18.4831 26.5219 18.9295C26.1574 19.3759 25.9584 19.9346 25.9584 20.5109C25.9584 21.0872 26.1574 21.6458 26.5219 22.0922C26.8863 22.5387 27.3938 22.8455 27.9584 22.9609C28.0995 22.9895 28.2264 23.0661 28.3175 23.1776C28.4086 23.2891 28.4584 23.4286 28.4584 23.5726V25.5109C28.4584 25.6766 28.3925 25.8356 28.2753 25.9528C28.1581 26.07 27.9991 26.1359 27.8334 26.1359H12.8334C12.6676 26.1359 12.5086 26.07 12.3914 25.9528C12.2742 25.8356 12.2084 25.6766 12.2084 25.5109V23.5726Z"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
