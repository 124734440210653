import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { Offer } from '@/services/PifService'

export function useSelectOffer({ offers, defaultOfferId }: { offers: Offer[]; defaultOfferId?: string }) {
  const defaultOffer = useMemo(() => {
    return offers.find((offer) => offer?.id === defaultOfferId) || offers?.[0]
  }, [defaultOfferId, offers])
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(defaultOffer)

  const router = useRouter()

  useEffect(() => {
    const query = router?.query
    const queriedOfferId = Array.isArray(query?.offer) ? query?.offer?.[0] : query?.offer
    const preferredOffer = offers.find((offer) => offer.id === queriedOfferId)
    if (preferredOffer) {
      setSelectedOffer(preferredOffer)
    }
  }, [offers, router?.query])

  return {
    setSelectedOffer,
    selectedOffer,
  }
}
