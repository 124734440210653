import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const UpArrowCircleIcon: React.FC<OneColorIconProps> = ({ size = 48, color = 'gray-500', className }) => {
  const strokeColor = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6362 22.8639L24.0002 16.5L30.3641 22.8639"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24 31.5V16.5" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
