import React from 'react'
import { getHexColor, TwoColorIconProps } from '../utils'

export const CircleGiftFilled: React.FC<TwoColorIconProps> = ({
  size = 40,
  color1 = 'gray-100',
  color2 = 'oxide-700',
  className,
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.666748" y="0.510864" width="40" height="40" rx="20" fill={c2} />
      <path
        d="M27.5417 16.7609H13.7917C13.4466 16.7609 13.1667 17.0407 13.1667 17.3859V19.8859C13.1667 20.231 13.4466 20.5109 13.7917 20.5109H27.5417C27.8869 20.5109 28.1667 20.231 28.1667 19.8859V17.3859C28.1667 17.0407 27.8869 16.7609 27.5417 16.7609Z"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9167 20.5109V26.1359C26.9167 26.3016 26.8509 26.4606 26.7337 26.5778C26.6165 26.695 26.4575 26.7609 26.2917 26.7609H15.0417C14.876 26.7609 14.717 26.695 14.5998 26.5778C14.4826 26.4606 14.4167 26.3016 14.4167 26.1359V20.5109"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6667 16.7609V26.7609"
        stroke="#F5F4F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2023 15.8769C23.3184 16.7608 20.6667 16.7608 20.6667 16.7608C20.6667 16.7608 20.6667 14.1092 21.5506 13.2253C21.9023 12.874 22.3791 12.6767 22.8762 12.6769C23.3733 12.677 23.85 12.8746 24.2015 13.2261C24.553 13.5776 24.7506 14.0543 24.7507 14.5513C24.7508 15.0484 24.5536 15.5252 24.2023 15.8769V15.8769Z"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1312 15.8769C18.0151 16.7608 20.6667 16.7608 20.6667 16.7608C20.6667 16.7608 20.6667 14.1092 19.7828 13.2253C19.4311 12.874 18.9543 12.6767 18.4572 12.6769C17.9601 12.677 17.4834 12.8746 17.1319 13.2261C16.7804 13.5776 16.5829 14.0543 16.5828 14.5513C16.5826 15.0484 16.7799 15.5252 17.1312 15.8769V15.8769Z"
        stroke={c1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
