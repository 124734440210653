import React from 'react'
import { AsLabel, TitleSM } from '@/atoms/Text'
import { BaseIconProps } from '@/atoms/utils'

interface MissionExplainerCardProps {
  href?: string
  iconComponent: React.FC<BaseIconProps>
  heading: string
}

export const MissionExplainerCard: React.FC<MissionExplainerCardProps> = ({ href, heading, iconComponent: Icon }) => {
  return (
    <a
      href={href}
      className="flex items-center gap-4 rounded-xl border border-gray-200 bg-white p-6 md:flex-col md:items-start md:gap-6"
    >
      <Icon />
      <TitleSM weight="bold" as={AsLabel} color="core-gray-950">
        {heading}
      </TitleSM>
    </a>
  )
}
