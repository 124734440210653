import React, { FC } from 'react'
import { When } from 'react-if'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { Offer } from '@/services/PifService'
import { PayItForwardExpressCheckoutContainer } from '../PayItForwardExpressCheckoutContainer'

interface ProjectPIFData {
  name: string
  slug: string
  pifAvatar: string
  pifExplainerVideoUrl: string
  pifExplainerText: string
  logoCloudinaryPath: string
}

interface ViewProps {
  project: ProjectPIFData
  offers: Offer[]
  featuredOffer?: Offer
  featuredOffer15?: Offer
  featuredOffer80?: Offer
  featuredOffer100?: Offer
  handleOfferInViewChange: (inView: boolean) => void
  showBanner: boolean
}

export const PayItForwardDefaultPriceExperiment: FC<ViewProps> = ({
  offers,
  project,
  featuredOffer,
  featuredOffer15,
  featuredOffer80,
  featuredOffer100,
  handleOfferInViewChange,
  showBanner = false,
}) => {
  const showExperiment = Boolean(featuredOffer15 && featuredOffer80 && featuredOffer100)

  return (
    <div className="relative">
      <When condition={showExperiment}>
        <GBFadeInExperiment defaultValue={'fifteen'} experimentName={'default_pif_feature_offer'}>
          <ExperimentVariation variation={'fifteen'}>
            <PayItForwardExpressCheckoutContainer
              projectName={project.name}
              projectSlug={project.slug}
              offers={offers}
              featuredOffer={featuredOffer15}
              onChange={handleOfferInViewChange}
              showBanner={showBanner}
            />
          </ExperimentVariation>
          <ExperimentVariation variation={'eighty'}>
            <PayItForwardExpressCheckoutContainer
              projectName={project.name}
              projectSlug={project.slug}
              offers={offers}
              featuredOffer={featuredOffer80}
              onChange={handleOfferInViewChange}
              showBanner={showBanner}
            />
          </ExperimentVariation>
          <ExperimentVariation variation={'hundred'}>
            <PayItForwardExpressCheckoutContainer
              projectName={project.name}
              projectSlug={project.slug}
              offers={offers}
              featuredOffer={featuredOffer100}
              onChange={handleOfferInViewChange}
              showBanner={showBanner}
            />
          </ExperimentVariation>
          <ExperimentVariation default>
            <PayItForwardExpressCheckoutContainer
              projectName={project.name}
              projectSlug={project.slug}
              offers={offers}
              featuredOffer={featuredOffer}
              onChange={handleOfferInViewChange}
              showBanner={showBanner}
            />
          </ExperimentVariation>
        </GBFadeInExperiment>
      </When>
      <When condition={!showExperiment}>
        <PayItForwardExpressCheckoutContainer
          projectName={project.name}
          projectSlug={project.slug}
          offers={offers}
          featuredOffer={featuredOffer}
          onChange={handleOfferInViewChange}
          showBanner={showBanner}
        />
      </When>
    </div>
  )
}
