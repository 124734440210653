import React from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { useGoal } from '@/services/GoalService'
import { useViewsGoal } from '@/services/GoalService/GoalService'
import { ProjectFundingGoalDisplayV4, ProjectFundingGoalDisplayV5 } from './index'

export const ProjectFundingGoalWidget: React.FC<{
  className?: string
  isDarkMode?: boolean
  crowdfundingSlug?: string
}> = ({ crowdfundingSlug, className, isDarkMode = false }) => {
  const { slug } = useProjectMeta()

  const viewsGoal = 100000000
  const goalSlug = crowdfundingSlug ?? slug

  const { refetch, loading, error, raised, raisedRaw, cost, costRaw, percentProgress, backers, groupName, isActive } =
    useGoal({
      slug: goalSlug,
    })
  const { views } = useViewsGoal({ projectSlug: goalSlug })

  if (slug !== slugs.soundOfFreedom && (!goalSlug || !isActive)) return null
  const progress = Math.round((views / viewsGoal) * 100)

  return (
    <div
      className={classNames(
        !isDarkMode && 'bg-white border border-solid border-gray-200',
        'mb-6 lg:mb-16 rounded-xl px-4 md:px-6 py-4 lg:py-6 md:py-12',
        className,
      )}
    >
      <LazyHydrate whenVisible>
        <ThemeContextProvider isDarkMode={isDarkMode}>
          <When condition={slug === slugs.soundOfFreedom}>
            <ProjectFundingGoalDisplayV5
              loading={loading}
              error={error}
              raised={raised}
              raisedRaw={views}
              cost={cost}
              costRaw={viewsGoal}
              percentProgress={progress}
              backers={backers}
              groupName={groupName}
              slug={slug}
              refetch={refetch}
            />
          </When>
          <When condition={slug !== slugs.soundOfFreedom}>
            <ProjectFundingGoalDisplayV4
              loading={loading}
              error={error}
              raised={raised}
              raisedRaw={raisedRaw}
              cost={cost}
              costRaw={costRaw}
              percentProgress={percentProgress}
              backers={backers}
              groupName={groupName}
              slug={slug}
              refetch={refetch}
            />
          </When>
        </ThemeContextProvider>
      </LazyHydrate>
    </div>
  )
}
