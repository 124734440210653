import dynamic, { DynamicOptions } from 'next/dynamic'
import { PerkItemProps } from '../PerkTypes'

export const PerkItem = dynamic(
  import('./PerkItem')
    .then((mod) => mod.PerkItem)
    // eslint-disable-next-line no-console
    .catch((err) => console.error('Failed to load the PerkItem Experiment!', err)) as DynamicOptions<PerkItemProps>,
  {
    ssr: false,
  },
)
